import tippy from 'tippy.js'

export default () => {
  let placement = 'top'

  if ($(window).width() > 960) {
    placement = 'right'
  }

  tippy('[data-tippy-content]', {
    trigger: 'mouseenter focus click',
    placement: placement,
    theme: 'simplicate',
    maxWidth: 170
  })
}
